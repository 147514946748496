import React from 'react'
import * as stylesMenuDec from '@styles/menu-sidebar/menu.module.css' 
import { graphql, Link, useStaticQuery } from 'gatsby'

export default function Sidebar(){
  const data = useStaticQuery(graphql`
    query SiteInfoDec{
      site{
        siteMetadata {
          menuDec{
            title
            fontSize
            subtitle{
              textBold
              name
              link
            }
          }
        }
      }
    }
  `)
  const menuDec = data.site.siteMetadata.menuDec
  return (
    <div className={stylesMenuDec.container}>
      <img src="/media/images/dec/logo_dec.svg" alt="Dec" className={stylesMenuDec.dec} />
      <hr className={stylesMenuDec.separator} />

      <div className={stylesMenuDec.main}>
        {menuDec.map(menu => (
          <div key={menu.title}>
            <span className={stylesMenuDec.titlesMain} style={{ fontSize:menu.fontSize }}>{menu.title}</span>
            {menu.subtitle.map(subtitle => (
              <Link key={subtitle.name} to={subtitle.link} className={stylesMenuDec.link} activeClassName={stylesMenuDec.activeBorderLeft}>
                <p className={stylesMenuDec.titles}><span className={stylesMenuDec.titlesBold}>{subtitle.textBold}</span>{subtitle.name}</p>
              </Link>
            ))}
          </div>
        ))}
      </div>

      <hr className={stylesMenuDec.separator} />
      <Link to="/" className={stylesMenuDec.buttonMenu}>
        <p className={stylesMenuDec.titlesButtonMenu}>Inicio</p>
      </Link>
      <a
        href={process.env.URL_CONTACTS}
        target="_blank"
        rel="noreferrer"
        className={stylesMenuDec.buttonMenu}
      >
        <p className={stylesMenuDec.titlesButtonMenu}>Contáctanos</p>
      </a>
    </div>
  )
}
