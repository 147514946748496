// extracted by mini-css-extract-plugin
export var container = "menu-module--container--cDodW";
export var main = "menu-module--main--x0Z7M";
export var dec = "menu-module--dec--knmqU";
export var easySign = "menu-module--easySign--apyHt";
export var separator = "menu-module--separator--EexGW";
export var titlesMain = "menu-module--titlesMain--mTsEo";
export var titles = "menu-module--titles--X5Rc+";
export var titlesBold = "menu-module--titlesBold--LyCzk";
export var link = "menu-module--link--2G1kl";
export var buttonMenu = "menu-module--buttonMenu--KTBAa";
export var titlesButtonMenu = "menu-module--titlesButtonMenu--b+Rgp";
export var activeBorderLeft = "menu-module--activeBorderLeft--i54bK";